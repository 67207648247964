// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/SEARCH
// --------------------------------------------------

@import 'assets/nutshell/scss/components/search';

// --------------------------------------------------

// VARIABLES

// --------------------------------------------------

// PUT YOUR OWN TABLE STYLES HERE
.search {
  .even,
  .odd {
    padding-bottom: var(--base-spacing-unit);
    margin-bottom: var(--base-spacing-unit);
    border-bottom: var(--base-border);
  }

  .first {
    border-top: var(--base-border);
    padding-top: var(--base-spacing-unit);
  }
}
