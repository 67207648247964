// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// SASS-VARIABLES
$wrapper-max:   80em;

// --------------------------------------------------

@import 'assets/nutshell/scss/mixins/responsive';

// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

// PUT ADDITONAL RESPONSIVE MIXINS HERE
