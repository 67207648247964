// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/CONTACT
// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

.ce_contact {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;

  .contact__email {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .contact__image {
    position: relative;
  }

  .contact__details {
    height: auto;
    padding: var(--base-spacing-unit) 0 0;
  }

  .contact__name {
    @extend %_h4;

    color: var(--color-brand-primary);
  }
}
