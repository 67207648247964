// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES
html {
  --news-border: var(--base-border);
}

$news-grid-columns--sm: 2 !default;
$news-grid-columns--md: 2 !default;
$news-grid-columns--lg: 3 !default;

// USED FROM _variables.scss
// $base-spacing-unit
// $headings__font-weight;
// $headings__font-family;
// $heading__size-4
// $heading__l-h--4
// $heading__m-t--4
// $heading__m-b--4
// $color-links

// --------------------------------------------------

// news list
// [1] call every headline element (h1-h6)
// [2] headline for FE-Module
.news-list {
  margin-bottom: var(--base-spacing-unit);
  
  > { 
    #{headings()} {
      margin-bottom: 0;
    }
  }
  
  .info {
    margin-bottom: 0;
  }
    
  .float_left {
    margin-right: var(--base-spacing-unit);
  }
  
  .float_right {
    margin-left: var(--base-spacing-unit);
  } 
  
  .layout_short,
  .layout_latest,
  .layout_simple {
    
    > { 
      #{headings()} { 
        margin-top: var(--base-spacing-unit);
      }
    }
  }
  
  .layout_simple,
  .layout_upcoming {
    time,
    a {
      padding-top: var(--base-spacing-unit);
      display: inline-block;
    }
  }
  
  .header {
    margin-top: var(--base-spacing-unit);
  }
}

.news-grid {
  @include make-row;
  
  margin-bottom: var(--base-spacing-unit);
  
  > { 
    #{headings()} {
      @include make-col(12);
    }
  }

  .layout_short,
  .layout_latest,
  .layout_simple {
    @include column-floating;
    
    @include media-query(screen-sm) {
      @include make-col((12 / $news-grid-columns--sm));
    }
    
    @include media-query(screen-md) {
      @include make-col((12 / $news-grid-columns--md));
    }
    
    @include media-query(screen-lg) {
      @include make-col((12 / $news-grid-columns--lg));
    }
  }
  
  .layout_simple {
    time {
      display: block;
    }
  }
}

.news-menu {
  @include navigation();
    
  .year {
    margin-bottom: var(--base-spacing-unit--sm);
    
     > a,
     > .active {
      color: inherit;
      font-weight: var(--headings__font-weight);
      font-family: var(--headings__font-family);
    }
  }
  
  .level_2 li {
    margin-bottom: var(--base-spacing-unit--xs);
  }
}
