//
// NEWS
// --------------------------------------------------

// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/NEWSLETTER
// --------------------------------------------------

@import 'assets/nutshell/scss/components/newsletter';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --newsletter-list-font-family: var(--headings__font-family);
  --newsletter-list-font-weight: var(--headings__font-weight);
}

// --------------------------------------------------

.newsletter-subscribe {
  margin-bottom: 0;

  .widget-text,
  .widget-submit {
    margin-bottom: var(--base-spacing-unit);
    align-items: center;
    display: flex;
  }

  .submit {
    @extend button.btn--secondary;

    margin-left: var(--base-spacing-unit--sm);
  }
}

.newsletter-unsubscribe {
  .widget-text,
  .widget-submit {
    margin-bottom: var(--base-spacing-unit);
  }

  .submit {
    @extend button.btn--secondary;

    margin-left: var(--base-spacing-unit--sm);
  }
}

.newsletter-list {
  li {
    font-family: var(--newsletter-list-font-family);
    font-weight: var(--newsletter-list-font-weight);
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.newsletter-reader {
  padding-top: var(--base-spacing-unit);
  padding-bottom: var(--base-spacing-unit--sm);
}
