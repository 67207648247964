// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// TRUMPS/BROWSER-SUPPORT
// --------------------------------------------------

// SASS-VARIABLES (we use sass as older browsers might not understand custom properties var(--variable)
$browser-support-background:    #FFF8EA;
$browser-support-border:        2px solid #E25600;
$base-spacing-unit:             1rem;
$base-font-size--xs:            0.875rem;

// --------------------------------------------------

.browser-support {
  margin-top: $base-spacing-unit;
  margin-bottom: $base-spacing-unit;

  > .inside {
    @include wrapper-max;

    background: $browser-support-background;
    border: $browser-support-border;
    padding: $base-spacing-unit;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}
