// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/ACCORDION
// --------------------------------------------------

@import 'assets/nutshell/scss/components/accordion';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --accordion-padding:       0 var(--base-spacing-unit--sm) var(--base-spacing-unit);
  --accordion-background:    transparent;
  --accordion-border-radius: 0;

  --toggler-font-size:       var(--base-font-size);
  --toggler-background:      transparent;
  --toggler-color:           inherit;
  --toggler-spacing:         0;
  --toggler-border:          var(--base-border);
  --toggler-padding:         var(--base-spacing-unit) var(--base-spacing-unit--sm);
  --toggler-icon-size:       1;

  --toggler-font-family:     var(--base-font-family);
  --toggler-font-weight:     700;
}

// --------------------------------------------------

// default accordion
.ce_accordion {
  display: grid;
  align-self: start;

  .toggler {
    font-family: var(--toggler-font-family);
    font-weight: var(--toggler-font-weight);
    position: relative;
    border-radius: var(--accordion-border-radius);
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: var(--toggler-padding);
    vertical-align: middle;
    transition: border-radius 0.3s;
  }

  &:last-of-type {
    border-bottom: var(--base-border);
  }

  .ui-accordion-header-active {
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
  }

  // [1] define size for plus/cross
  .ui-accordion-header-icon {
    transition: transform 0.3s;
    float: right;
    display: inline-block;
    font-weight: 400;
    transform: scale(var(--toggler-icon-size)); // [1]

    &::before {
      content: '+';
    }
  }

  .ui-icon-triangle-1-s {
    transform: rotate(45deg) scale(var(--toggler-icon-size));
  }

  .ce_text {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
