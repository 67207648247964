// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/LINKS
// --------------------------------------------------

@import 'assets/nutshell/scss/components/links';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --btn-font-family:       var(--base-font-family-1);
  --button-font-weight:    700;
  --button-spacing:        var(--base-spacing-unit--sm) var(--base-spacing-unit);

  --button-bg-hover:       var(--color-brand-secondary);

  --btn--secondary-color:         var(--color-btn);
  --btn--secondary-color--dark:   var(--color-btn);

  --btn--primary-highlight-bg:      var(--color-text);
  --btn--primary-highlight-text:  var(--color-text--inverted);

  --btn--secondary-highlight-text:  var(--color-text--inverted);
}

// --------------------------------------------------

a {
  color: var(--color-links);

  &:hover {
    --color-links: var(--color-links-hover);
  }
}

%button {
  white-space: normal;

  &::after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
    vertical-align: top;
    position: relative;
    top: 0.5em;
    mask-image: url('/files/inhalte/icons/arrow-right.svg');
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: currentColor;
  }
}
%button--primary,
%button--secondary {
  --base-border-width: 1px;

  border-radius: 0;
  color: var(--btn--secondary-color);
  border: var(--base-border-width) solid currentColor;
  background: transparent;

  &:hover {
    --btn--secondary-color: var(--button-bg-hover);
    background: transparent;
  }

  .article--highlight & {
    color: var(--btn--secondary-highlight-text);
    border-color: var(--btn--secondary-highlight-text);

    &:hover {
      opacity: 0.75;
    }
  }
}

%button--secondary {
  font-size: 1.25rem;
}

// btn--primary & btn--secondary
a,
span,
button {
  &.btn--primary {
    text-decoration: none;

    &:hover {
      --button-bg: var(--button-bg-hover);
      --button-text: var(--color-text--inverted);
    }

    p & {
      margin-bottom: 0;
      text-decoration: none;
    }
  }

  &.btn--secondary {
    --base-border-width: 2px;

    border-radius: var(--base-border-radius);
    color: var(--btn--secondary-color);
    border: var(--base-border-width) solid currentColor;
    background: transparent;

    &:hover {
      --btn--secondary-color: var(--button-bg-hover);
      background: transparent;
    }

    .band--highlight & {
      color: var(--btn--secondary-highlight-text);
      border-color: var(--btn--secondary-highlight-text);

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

// [1] button used in a .band--highlight container
div.btn--primary {

  a {
    .band--highlight & { // [1]
      background: var(--btn--primary-highlight-bg);
      color: var(--btn--primary-highlight-text);
    }
  }
}

div.btn--secondary a {
  --base-border-width: 2px;

  border-radius: var(--base-border-radius);
  color: var(--btn--secondary-color);
  border: var(--base-border-width) solid currentColor;

  &:hover {
    --btn--secondary-color: var(--button-bg-hover);
  }

  .band--highlight & {
    color: var(--btn--secondary-highlight-text);
    border-color: var(--btn--secondary-highlight-text);

    &:hover {
      opacity: 0.5;
    }
  }
}

div.btn--feedback {
  position: fixed;
  z-index: 1002;
  right: 0;
  top: var(--base-spacing-unit--xl);
  transform: rotate(-90deg) translateY(var(--base-spacing-unit--xs));
  transform-origin: bottom right;
  transition: transform 0.1s;

  &:hover {
    transform: rotate(-90deg) translateY(0);
  }

  a {
    padding: var(--base-spacing-unit--xs) var(--base-spacing-unit--sm);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: var(--base-border);
    border-bottom: none;
  }
}

.back {
  //@extend %icon-arrow-left;
}
