// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/COMMENTS
// --------------------------------------------------

// VARIABLES
html {
  --color-error: rgba(255, 0, 0, 1);
  --color-error-light: rgba(255, 0, 0, 0.5);
}

// USED FROM _variables.scss
// var(--base-spacing-unit);

// --------------------------------------------------


// default comments, used for news and as content element
.ce_comments {
  overflow: visible;
  
  .widget {
    margin-bottom: var(--base-spacing-unit);
  }
  
  p.error {
    margin-bottom: 0;
    
    color: var(--color-error);
  }
  
  .error {
    border-color: var(--color-error-light);
  }
  
  .submit {
    @extend %button;
    @extend %button--primary;
  }
}

.comment_default {
  padding: var(--base-spacing-unit);
  margin-bottom: var(--base-spacing-unit);
  border: var(--base-border);
  
  .info {
    padding-bottom: var(--base-spacing-unit);
    border-bottom: var(--base-border);
  }
  
  &.last {
    margin-bottom: var(--base-spacing-unit--lg);
  }
}
