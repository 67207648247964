// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *
  
//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// VARIABLES
// NOTE: we use 'em' as unit as you get cross-browser problems, when you
// change the browser base font-size, see http://zellwk.com/blog/media-query-units/
// [1] in fact 66.625em = 1066px, but 1066px, but since we have a font-size increase at screen-xl-min,
//     we end up at ~1200px (see _page.scss)
//
$wrapper-max:         66.625em !default; // ~1200px [1]


// $breakpoints: will be used in _grid.scss to generate grid classes
$breakpoints: (
  xs: (
    min:        0,
    max:        (34.375em - 0.0625em), // 549px
  ),
  sm: (
    min:        34.375em, // 550px
    max:        (48em - 0.0625em), // 767px
  ),
  md: (
    min:        48em, // 768px
    max:        (58.75em - 0.0625em), // 939px
  ),
  lg: (
    min:        58.75em, // 940px
    max:        (68.75em - 0.0625em), // 1099px
  ),
  xl: (
    min:        68.75em, // 1100px
    max:        "none"
  ),
) !default;


$additional_breakpoints: (
  menu: (
    min:        48em, // 768px
    max:        (48em - 0.0625em), // 767px
  )
) !default;

$all_breakpoints: map-merge($breakpoints, $additional_breakpoints);


// USED FROM _variables.scss

// --------------------------------------------------

@mixin wrapper-max($width: $wrapper-max) {
  max-width: $width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin media-query($mq, $mq-max: false) {

    $breakpoint-found: false;

    // Loop through the list of breakpoints we’ve provided in our settings file.
    @each $breakpoint, $screen in $all_breakpoints {

        // Grab the alias and the condition from their respective locations in
        // the list.
        
        // min-width
        $size:      $breakpoint; // (xs)
        
        $alias-min:     "screen-#{$size}"; // (screen-xs)
        $alias-max:     "screen-#{$size}-max"; // (screen-xs-max)
        $condition-min: map-get($screen, min);
        $condition-max: map-get($screen, max);
        
        //@warn "mq: #{$mq}";
        
        //@warn "size: #{$size}, alias: #{$alias-min} - #{$alias-max}, condition: #{$condition-min} #{$condition-max}";
        
        
        // If the media query we’ve specified has an alias and a condition...
        @if $mq == $alias-min and $mq-max == false {

            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media screen and (min-width: #{$condition-min}) {
              @content;
            }

        }
        @if $mq == $alias-max {
            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media screen and (max-width: #{$condition-max}) {
              @content;
            }
        }
        @if ($mq == $alias-min and $mq-max == $alias-max)  {
            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media screen and (min-width: #{$condition-min}) and (max-width: #{$condition-max}) {
              @content;
            }
        }

    }

    // If the user specifies a non-exitent alias, send them a warning.
    @if $breakpoint-found == false{
        @warn "Arrr! Breakpoint ‘#{$mq}’ existiert nicht."
    }

}
