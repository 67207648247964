// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/FACTS
// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

.fact__item {
  border: var(--base-border);
  padding: var(--base-spacing-unit);
  text-align: center;
  align-items: center;
  margin-bottom: 0;
  display: grid;
  height: 100%;
  align-content: center;

  @supports not (display: grid) {
    margin-bottom: var(--base-spacing-unit);
  }

  #{headings()} {
    @extend %_h5;

    text-transform: uppercase;

    + p {
      @extend %_h1;

      font-family: var(--base-font-family-1);
      margin-bottom: 0;
      color: var(--color-brand-secondary);
      opacity: 0.5;
    }
  }
}
