// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/COMMENTS
// --------------------------------------------------

@import 'assets/nutshell/scss/components/comments';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --reply-background: var(--color-gray-light);
  --form-background:  var(--color-gray-light);
}

// --------------------------------------------------

.ce_comments {
  @include media-query(screen-md) {
    @supports (display: grid) {
      @include make-row;
    }
  }

  margin-bottom: var(--base-spacing-unit);

  h2,
  .form {
    @include media-query(screen-md) {
      @supports (display: grid) {
        @include make-col(8);

        grid-column-start: 4;
      }
    }
  }

  .form {
    border: var(--base-border);
    padding: var(--base-spacing-unit);

    @include media-query(screen-md) {
      padding: var(--base-spacing-unit--lg);
    }
  }

  .formbody {
    @include media-query(screen-md) {
      @include make-width(8);
    }
  }

  .widget-submit {
    margin-bottom: 0;
  }
}

.comment_default {
  @include media-query(screen-md) {
    @supports (display: grid) {
      @include make-col(8);

      grid-column-start: 4;
    }
  }

  .info {
    padding-bottom: var(--base-spacing-unit--sm);
    margin-bottom: var(--base-spacing-unit--sm);
  }

  .reply {
    margin-top: var(--base-spacing-unit);

    @include media-query(screen-sm) {
      padding: var(--base-spacing-unit);
      background-color: var(--reply-background);
      border-radius: var(--base-border-radius);
    }

    .info {
      font-style: italic;
    }
  }
}
