// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/TEXT
// --------------------------------------------------

.text--contact {
  text-align: right;
  border-right: var(--base-spacing-unit--sm) solid var(--color-brand-primary);
  padding-right: var(--base-spacing-unit--lg);

  #{headings()} {
    font-size: 2.5rem;
    line-height: 1;

    margin-bottom: 0
  }

  p {
    @extend %_--hero-headline;

    line-height: 1;
  }
}

.text--condensed {
  font-family: var(--base-font-family-2);
}
