// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// SASS-VARIABLES
$news-grid-columns--sm: 1;
$news-grid-columns--md: 2;
$news-grid-columns--lg: 2;

// --------------------------------------------------

@import 'assets/nutshell/scss/components/news';

// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

// [1] override link color to text-color
// [2] images will only be shown in .layout_latest
.news-list {
  display: grid;
  grid-row-gap: var(--grid__gutter);
  margin-bottom: var(--grid__gutter);

  > {
    #{headings()} {
      @extend %_h5;

      margin-bottom: var(--base-spacing-unit--sm);
    }
  }

  .layout_latest {
    display: grid;
    border: var(--base-border);

    @supports not (display: grid) {
      display: flex;
      border: none;
    }

    .ie & {
      display: flex;
      border: none;
    }

    @include media-query(screen-md) {
      @include make-row;
    }

    .image_container {
      @include media-query(screen-md) {
        @include make-col(7);
      }

      @include media-query(screen-lg) {
        @include make-col(8);
      }

      img {
        object-fit: cover;
        max-width: none;
        width: 100%;
        height: 100%;
      }
    }

    .content_container {
      padding: var(--base-spacing-unit);
      display: grid;
      grid-template-rows: auto auto 1fr auto;

      @include media-query(screen-md) {
        @include make-col(5);

        padding: var(--base-spacing-unit) var(--base-spacing-unit--lg) var(--base-spacing-unit) 0;
      }

      @include media-query(screen-lg) {
        @include make-col(4);
      }
    }

    #{headings()} {
      @extend %_h4;

      margin-top: 0;

      a {
        color: inherit;
      }
    }

    .ce_text {
      font-size: var(--base-font-size--xs);
      margin-bottom: var(--base-spacing-unit);
    }

    .more {
      font-size: var(--base-font-size--xs);
      margin-bottom: 0;

      a {
        text-decoration: none;
        white-space: nowrap;
      }
    }

    .info {
      color: var(--color-gray);
      margin-bottom: 0;
      font-size: var(--base-font-size--xs);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
	  }
  }

  .layout_short {
    border: var(--base-border);
    padding: var(--base-spacing-unit);

    @supports not (display: grid) {
      margin-bottom: var(--grid__gutter);
    }

    .ie & {
      margin-bottom: var(--grid__gutter);
    }

    .info {
      color: var(--color-gray);
      margin-bottom: 0;
      font-size: var(--base-font-size--xs);
	  }

    #{headings()} {
      @extend %_h4;

      margin-top: 0;

      a {
        color: inherit;
      }
    }

    .more {
      font-size: var(--base-font-size--xs);
      margin-bottom: 0;

      a {
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }

  .layout_simple {
    @extend %_h3;
    @include make-row;

    position: relative;
    font-family: var(--base-font-family);

    > a {
      @include make-col(2);
    }

    time {
      display: block;
      font-size: 1rem;

      & + a {
        padding-top: 0;
      }
    }

    .content_container {
      @include make-col(10);
    }

    a {
      padding-right: var(--base-spacing-unit);
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
    }
  }

  // .layout_latest // [2]
  .image_container {
    grid-column: 1;

    img {
      margin-left: 0;
    }
  }
}


.news-grid {
  grid-row-gap: var(--grid__gutter);

  @supports not(display: grid) {
    display: flex;
    margin-left: calc(-1 * var(--grid__gutter) / 2);
    margin-right: calc(-1 * var(--grid__gutter) / 2);
  }

  .ie & {
    display: flex;
    margin-left: calc(-1 * var(--grid__gutter) / 2);
    margin-right: calc(-1 * var(--grid__gutter) / 2);
  }

  .layout_short,
  .layout_latest,
  .layout_simple {
    display: grid;
    grid-template-rows: min-content 1fr;
    border: var(--base-border);

    @supports not (display: grid) {
      border: none;
    }

    .ie & {
      border: none;
    }
  }

  .image_container {
    overflow: hidden;
  }

  .content_container {
    padding: var(--base-spacing-unit) 0;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: 2;

    @supports (display: grid) {
      padding: var(--base-spacing-unit);
    }

    a {
      display: block;
    }

    &:first-child {
      grid-row: span 2;
    }

    @include media-query(screen-md) {
      @supports (display: grid) {
        padding: var(--base-spacing-unit) var(--base-spacing-unit--lg);
      }
    }
  }

  #{headings()} {
    @extend %_h4;

    grid-column: span 2;
    margin-top: 0;

    a {
      color: inherit;
    }
  }

  .info {
    justify-self: end;
    grid-row: 3;
    grid-column: 2;
    color: var(--color-gray);
    margin-bottom: 0;
    font-size: var(--base-font-size--xs);
    white-space: nowrap;
  }

  .ce_text {
    font-size: var(--base-font-size--xs);
    margin-bottom: var(--base-spacing-unit);
    grid-column: span 2;
  }

  .more {
    grid-column: 1;
    font-size: var(--base-font-size--xs);

    a {
      --paragraph__link-decoration: none;
    }
  }

  .pagination {
    @supports not (display: grid) {
      padding-left: var(--grid__gutter--half);
      padding-right: var(--grid__gutter--half);
    }

    .ie & {
      padding-left: var(--grid__gutter--half);
      padding-right: var(--grid__gutter--half);
    }
  }

  .layout_simple {

    @supports not(display: grid) {
      margin-bottom: var(--grid__gutter);
    }

    .ie & {
      margin-bottom: var(--grid__gutter);
    }

    .content_container {
      --color-background: var(--color-brand-secondary);

      padding: 0;
      background: var(--color-background);
      transition: background 0.3s;

      &:hover {
        --color-background: var(--color-brand-primary);
      }

      a {
        --color-links: var(--color-text--inverted);

        padding: var(--base-spacing-unit) var(--base-spacing-unit--lg);
      }
    }
  }
}

.news-full {
  .layout_full {
    display: grid;

    @include media-query(screen-md) {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--grid__gutter);
      grid-row-gap: var(--grid__gutter);
      grid-template-areas:
      'hl hl hl hl hl hl hl hl hl hl hl hl'
      'meta meta meta ce ce ce ce ce ce ce ... ...';
    }


    [class*='ce_'] {
      @include media-query(screen-md) {
        grid-column: ce / span 8;
      }
    }

    h1 {
      position: relative;
      z-index: 1000;
      text-align: left;
      align-self: center;
      margin-top: var(--base-spacing-unit);
      margin-bottom: var(--base-spacing-unit);

      @include media-query(screen-md) {
        grid-row: hl;
        grid-column: hl / span 10;
      }

      @include media-query(screen-lg) {
        margin-top: var(--base-spacing-unit--lg);
        margin-bottom: var(--base-spacing-unit--lg);
      }

      @include media-query(screen-xl) {
        grid-column: hl / span 9;
        margin-top: var(--base-spacing-unit--xl);
        margin-bottom: var(--base-spacing-unit--xl);
      }
    }

    .info {
      @include media-query(screen-md) {
        grid-column: meta;
      }

      time {
        display: block;
      }
    }

    .ce_image {
      &:first-of-type {
        grid-column: img / span 14;

        .image_container {
          border: none;
        }
      }
    }

  }


  .back {
    font-family: var(--base-font-family);
    grid-column: span 4;
  }
}


.news-full--hero {
  display: grid;
  grid-template-columns: calc(5% - var(--grid__gutter)) repeat(12, 1fr) calc(5% - var(--grid__gutter));
  grid-column-gap: var(--grid__gutter);

  @supports not(display: grid) {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
  }

  .ie & {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
  }

  .layout_full {
    grid-column: span 14;
    display: grid;
    grid-column-gap: var(--grid__gutter);
    grid-template-areas:
    '... hl hl hl hl hl hl hl hl hl hl ... ... ...'
    'img img img img img img img img img img img img img img'
    '.. ce ce ce ce ce ce ce ce ce ce ce ce ..';
    grid-template-columns: calc(5% - var(--grid__gutter)) repeat(12, 1fr) calc(5% - var(--grid__gutter));
    grid-template-rows: auto 1fr;

    @include media-query(screen-md) {
      grid-template-areas:
      '... hl hl hl hl hl hl hl hl hl hl ... ... ...'
      'img img img img img img img img img img img img img img'
      '... ... ... ce ce ce ce ce ce ce ce ... ... ...';
    }

    [class*='ce_'] {
      grid-column: ce / span 12;

      @include media-query(screen-md) {
        grid-column: ce / span 8;
      }
    }


    h1 {
      grid-column: hl / span 12;
      grid-row: hl;
      position: relative;
      z-index: 1000;
      text-align: left;
      align-self: center;
      margin-top: var(--base-spacing-unit);
      margin-bottom: var(--base-spacing-unit);

      @include media-query(screen-md) {
        grid-column: hl / span 10;
      }

      @include media-query(screen-lg) {
        margin-top: var(--base-spacing-unit--lg);
        margin-bottom: var(--base-spacing-unit--lg);
      }

      @include media-query(screen-xl) {
        grid-column: hl / span 9;
        margin-top: var(--base-spacing-unit--xl);
        margin-bottom: var(--base-spacing-unit--xl);
      }
    }

    .ce_image {
      &:first-of-type {
        grid-column: img / span 14;
        margin-bottom: var(--grid__gutter);

        img {
          border: none;
        }
      }
    }
  }

  .back {
    font-family: var(--base-font-family);
    grid-column: 2 / span 12;

    @supports not (display: grid) {
      width: 100%;
    }

    .ie & {
      width: 100%;
    }

    @include media-query(screen-md) {
      grid-column: 4 / span 8;
    }
  }
}

.news-menu {

  > {
    #{headings()} {
      @extend %_h6;

      padding-bottom: var(--base-spacing-unit--sm);
      margin-bottom: var(--base-spacing-unit--sm);
      border-bottom: var(--base-border);
    }
  }

  a,
  .active {
    text-decoration: none;
    color: inherit;
    font-family: var(--hl__font-family);
  }

  .level_2 {
    a,
    span.active {
      vertical-align: middle;
      text-decoration: none;

      &::before {
        content: '»';
        margin-right: calc(var(--base-spacing-unit--xs) / 2);
      }
    }
  }

  .year {
    > a,
    > .active {
      font-weight: var(--headings__font-weight);
    }
  }
}
