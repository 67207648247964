// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/PORTFOLIO
// --------------------------------------------------

// CSS-VARIABLES
:root {
  --portfolio-background: var(--color-tint);
}

// --------------------------------------------------

.portfolio-list {
  .portfolio__filter {
    @include navigation;

    margin-bottom: var(--base-spacing-unit--lg);
    position: relative;
    z-index: 1000;



    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      touch-action: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;

      @include media-query(screen-menu) {
        justify-content: center;
      }
    }

    li {
      margin-right: 0.5rem;
      white-space: nowrap;
      position: relative;
      z-index: 1001;
      text-align: center;
    }

    button {
      color: var(--color-brand-primary);
      padding: 0.25rem 1rem;
      border: 1px solid var(--color-brand-primary);
      background: transparent;
      width: 100%;
      font-weight: 700;

      &:focus {
        outline: none;
      }

      &.active {
        background: var(--color-brand-primary);
        color: var(--color-text--inverted);
      }
    }
  }

  .portfolio__items,
  .projects__items {
    @include make-row;

    grid-row-gap: var(--grid__gutter);

    @supports not(display: grid) {
      display: flex;
      margin-left: calc(-1 * var(--grid__gutter) / 2);
      margin-right: calc(-1 * var(--grid__gutter) / 2);
    }

    .ie & {
      display: flex;
      margin-left: calc(-1 * var(--grid__gutter) / 2);
      margin-right: calc(-1 * var(--grid__gutter) / 2);
    }

    .layout_short {
      @include make-col(12);

      grid-template-rows: min-content 1fr;

      .ie & {
        border: none;
      }

      @include media-query(screen-md) {
        @include make-col(6);
      }

      @include media-query(screen-lg) {
        @include make-col(4);
      }
    }

    .inside {
      display: grid;
      height: 100%;
      grid-template-rows: min-content auto;
    }

    .image_container {
      overflow: hidden;
    }

    .portfolio__meta {
      margin-top: 0.5rem;
      margin-bottom: 0;
      font-family: var(--base-font-family-2);
    }

    h2 {
      @extend %_h4;

      margin-top: 0;

      a {
        color: inherit;

        @extend %icon-arrow-right;
      }
    }
  }
}

.portfolio-full {
  @include make-row;

  grid-column-gap: var(--grid__gutter);

  .layout_full {
    grid-column: 1 / -1;
  }

  .layout_full {
    @include make-row;
  }

  h1,
  .portfolio__meta {
    @include make-col(12);

    text-align: center;
    font-family: var(--base-font-family-2);

    @include media-query(screen-md) {
      @include make-col(8);
      @include make-start(3);
    }
  }

  .portfolio__meta {
    margin-bottom: var(--base-spacing-unit);
    display: flex;
    justify-content: center;
    gap: var(--grid__gutter--half);
  }

  .portfolio__categories {
    @include navigation;
  }

  .portfolio__content {
    @include make-col(12);
    @include make-row;

    font-family: var(--base-font-family-2);
  }

  .ce_text {
    @include make-col(12);

    @include media-query(screen-md) {
      @include make-col(8);
      @include make-start(3);
    }

    text-align: center;
    margin-bottom: var(--base-spacing-unit--lg);
  }

  .ce_gallery {
    @include make-col(12);

    margin-top: var(--base-spacing-unit--lg);
    margin-bottom: var(--base-spacing-unit--lg);
  }

  .back {


    @include media-query(screen-md) {
      @include make-col(12);
    }
  }
}
