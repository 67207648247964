// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

@import 'assets/nutshell/scss/components/slider';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --arrow-color:               rgba(255, 255, 255, 0.75);
  --arrow-size:                2rem;
  --menu-point-size:           3rem;
  --menu-point-color:          var(--color-text--inverted);
  --menu-point-color--active:  var(--color-brand-secondary);
  --arrow-padding:              var(--base-spacing-unit--sm);
}

// --------------------------------------------------

// sass-lint:disable no-universal-selectors
.slider {
  .slider-wrapper > * {
    margin-bottom: 0;
  }

  .slider-control a {
    display: none;
    width: calc(var(--arrow-size) + var(--arrow-padding) * 2);
    height: calc(var(--arrow-size) + var(--arrow-padding) * 2);
    overflow: hidden;
  }

  .slider-menu {
    b {
      margin: 0 var(--base-spacing-unit--sm);
      font-family: Arial, sans-serif;
    }
  }

}

.ce_sliderStart {
  @extend .slider;
}
