// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// BASE/ICONS
// --------------------------------------------------

// SASS-VARIABLES
$image_path: '/files/inhalte/icons';

// --------------------------------------------------

%icon-arrow-right {
  display: flex;

  &::before {
    content: '»';
    text-indent: -9999em;
    mask: url('#{$image_path}/arrow-right.svg') left center / contain no-repeat;
    background-color: currentColor;
    width: 0.4rem;
    display: block;
    align-self: flex-start;
    margin-right: 0.5rem;
  }
}

%icon-arrow-left {
  display: flex;

  &::before {
    content: '»';
    text-indent: -9999em;
    mask: url('#{$image_path}/arrow-left.svg') left center / contain no-repeat;
    background-color: currentColor;
    width: 0.5rem;
    display: block;
    flex-shrink: 0;
    align-self: flex-start;
    margin-right: 0.5rem;
  }
}
