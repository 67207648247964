// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/FORMS
// --------------------------------------------------

@import 'assets/nutshell/scss/components/forms';

// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

// sass-lint:disable-block force-element-nesting
input.text,
input.captcha,
textarea {
  padding: var(--base-spacing-unit--xs) var(--base-spacing-unit--sm);
}

input.radio,
input.checkbox {
  vertical-align: middle;
}

.widget-radio--list {
  .radio_container {
    > span {
      display: flex;
    }
  }
}

.widget-checkbox--list {
  .checkbox_container {
    > span {
      display: flex;
    }
  }
}
