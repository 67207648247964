// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/CARD
// --------------------------------------------------

// CSS-VARIABLES
:root {

}

// --------------------------------------------------

.ce_card {
  margin-bottom: var(--base-spacing-unit);

  .card__image {
    margin-bottom: var(--base-spacing-unit);

    @include media-query(screen-lg) {
      margin-bottom: var(--base-spacing-unit--lg);
    }

  }

  .card__headline {
    @extend %_h3;
  }

  .card__link {
    margin-top: auto;
    margin-bottom: 0;
    padding-top: var(--base-spacing-unit--lg);

    a {
      @extend %button;
      @extend %button--secondary;
    }
  }
}
