// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *


// VARIABLES

// --------------------------------------------------

@import 'assets/nutshell/scss/variables';

// --------------------------------------------------

:root {
  // General Colors
  --color-gray:              rgb(99, 101, 106);
  --color-gray-light:        #F0F0F0;
  --color-gray-lighter:      #F2F2F2;

  --color-blue-darker:        #284456;
  --color-blue-dark:          rgb(49, 109, 157); //PH blue for article highlight 2
  //--color-blue:               hsl(206, 72%, 47%);
  //--color-blue-light:         hsl(206, 72%, 67%);
  //--color-blue-lighter:       #738591;

  --color-gold:              rgb(208,158,65);
  --color-brown:             rgb(130, 64, 28);


  --color-tint:              var(--color-gray-light);

  --color-brand-primary:     var(--color-gold);
  --color-brand-secondary:   var(--color-brown);

  --color-brand:             var(--color-brand-primary);
  --color-highlight:         var(--color-brand);

  // Page
  --color-page-background:   #F0F0F0;
  --color-text:              var(--color-gray);

  --color-text--light:       var(--color-gray);

  // Links
  --color-links:             var(--color-gold);
  --color-links-hover:       var(--color-brown);

  // Buttons
  --color-btn:               var(--color-brand-primary);
  --color-btn-text:          #FFFFFF;


  // Base Font Settings
  --base-font-size:                  1rem;
  --base-line-height:                1.8;

  --base-font-size--xs:              0.875rem;

  --base-font-size--lg:              1rem;
  --base-font-size--xl:              1rem;

  --base-font-family-1:              'Roboto', 'Helvetica Neue', Arial, sans-serif;
  --base-font-family-2:              'Roboto Condensed', 'Helvetica Neue', Arial, sans-serif;

  --base-font-family:                var(--base-font-family-1);

  // Base Spacing Units
  --base-spacing-unit:   1rem;


  // Base Border Settings
  --base-border-radius:  4px;
  --base-border-width:   1px;
  --base-border-color:   #D0D0D0;


  // Articles
  --color-article-highlight-background:     var(--color-highlight);
  --color-article-highlight-2-background:     var(--color-blue-dark);
  --color-article-tint-background:          var(--color-tint);
  --color-article-dark-text:                #FFFFFF;
  --color-article-dark-background:          var(--color-text);
}
