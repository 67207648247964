// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/HERO
// --------------------------------------------------

// CSS-VARIABLES
:root {
  --hero__color-background: #FFFFFF;
  --hero__color-text:       #FFFFFF;
  --hero__font-family:      var(--base-font-family);
  --hero__font-weight:      700;
}

// --------------------------------------------------

// hero
// [1] every .inside container has position relative by contao default
// [2] calculate height based on padding for mod_article, including fallback
// [3] flexbox fix for IE10 & IE11, see http://caniuse.com/#feat=flexbox

// sass-lint:disable-block force-element-nesting
.mod_article.hero {
  position: relative;
  padding: 0;
  z-index: 0;

  > .inside {
    @include wrapper-max(none);

    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    position: static; // [1]
    min-height: inherit;

    @supports not(display: grid) {
      display: flex;
      flex: 0 0 auto;
    }

    .ie & {
      display: flex;
      flex: 0 0 auto;
    }
  }
}

.ce_hero {
  width: 100%;
  display: grid;
  grid-template-columns: [image-start] minmax(0, 1fr) [main-start] minmax(0, $wrapper-max) [main-end] minmax(0, 1fr) [image-end];

  .hero__background {
    grid-column: image;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .hero__background {
    grid-row: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .hero__content {
    grid-row: 1 / -1;
    color: var(--hero__color-text);
    grid-column: main;
    position: relative;
    z-index: 1000;
    text-align: left;
    align-self: center;
    margin-top: var(--base-spacing-unit);
    margin-bottom: var(--base-spacing-unit);
    padding-left: var(--wrapper-padding);
    padding-right: var(--wrapper-padding);

    @include make-row;

    @include media-query(screen-lg) {
      padding-left: var(--base-spacing-unit--lg);
      margin-top: var(--base-spacing-unit--lg);
      margin-bottom: var(--base-spacing-unit--lg);
    }

    @include media-query(screen-xl) {
      margin-top: var(--base-spacing-unit--xl);
      margin-bottom: var(--base-spacing-unit--xl);
    }
  }

  // text left
  .hero__text {
    grid-column: span 9;
    border-left: var(--base-spacing-unit--sm) solid var(--color-brand-secondary);
    padding-left: var(--base-spacing-unit);

    @include media-query(screen-md) {
      grid-column: 1 / span 7;
    }

    @include media-query(screen-lg) {
      grid-column: 1 / span 7;
    }
  }

  .hero__headline {
    color: var(--hero__color-text);

    @extend %_--hero-headline;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: var(--base-spacing-unit);
    margin-bottom: 0;
    display: inline-block;
  }

  a {
    color: inherit;
  }
}

.hero--text-right {
  .hero__text {
    text-align: right;
    border-right: var(--base-spacing-unit--sm) solid var(--color-brand-secondary);
    padding-right: var(--base-spacing-unit--lg);
    padding-left: 0;
    border-left: none;
    grid-column: span 12;
  }

  .hero__headline {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 0
  }

  p {
    @extend %_--hero-headline;

    line-height: 1;
  }
}
