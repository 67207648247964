// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// BASE/LAYOUT
// --------------------------------------------------
// SASS-VARIABLES
$boxed-layout: false;

// --------------------------------------------------

@import 'assets/nutshell/scss/base/layout';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --wrapper-background:                          #FFFFFF;
  --wrapper-padding:                             3.5vw;
  --header-color-background:                     #FFFFFF;
  --header-color-background--inverted:           var(--color-article-dark-background);
  --header-color-text--invert:                   #FFFFFF;

  --footer-background:                           var(--color-brand-primary);
}

// --------------------------------------------------

// nav--mobile--active
.html--fixed {
  @include media-query(screen-menu-max) {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

body {
  overflow-x: hidden;
}

// #wrapper
// [1] full height layout with css grid layout
[id='wrapper'] {
  min-height: 100vh; // [1]
  display: grid; // [1]
  grid-template-rows: min-content auto min-content; // [1]
  margin-left: auto;
  margin-right: auto;
  background: var(--wrapper-background);

  @if ($boxed-layout) {
    max-width: $wrapper-max;
  }
}

// #header
[id='header'] {
  background: var(--header-color-background);
  z-index: 1001;
  left: 0;
  right: 0;
  flex: none;
  color: var(--header-color-text);

  > .inside {
    @include wrapper-max();

    padding-left: var(--wrapper-padding);
    padding-right: var(--wrapper-padding);
    padding-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    position: relative;

    @supports not (display: grid) {
      display: flex;
    }

    .ie & {
      display: flex;
    }
  }

  html {
    &.html--fixed & {
      pointer-events: none;
    }
  }
}

// #container
// [1] sticky footer via flexbox
// [2] IE10: sticky footer, fix gap between #container and #footer
[id='container'] {
  flex: 1 0 auto; // [1]
  overflow: hidden; // [2]
}

// #main
.mod_article {
  @include wrapper-max(none);

  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 0;
  padding-right: 0;

  @include media-query(screen-lg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  @include media-query(screen-xl) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  > .inside {
    @include wrapper-max();

    padding-left: var(--wrapper-padding);
    padding-right: var(--wrapper-padding);

    > .last {
      margin-bottom: 0;
    }
  }
}

.article--full {
  @include wrapper-max(none);
}

// #footer
[id='footer'] {
  flex: none;
  background: var(--footer-background);
  color: var(--color-text--inverted);

  > .inside {
    @include wrapper-max();

    padding-left: var(--wrapper-padding);
    padding-right: var(--wrapper-padding);
    display: grid;
    text-align: center;
    align-items: center;
    padding-top: var(--base-spacing-unit--lg);
    padding-bottom: var(--base-spacing-unit);

    @include media-query(screen-md) {
      grid-template-columns: auto auto auto;
      justify-content: space-between;
      text-align: left;
    }

    @include media-query(screen-xl) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

// band
.mod_article {
  position: relative;
}

.article--highlight {
  background-color: var(--color-article-highlight-background);
  color: var(--color-text--inverted);

  a {
    color: inherit;
  }

  --headings__color: var(--color-brand-secondary);
}

.article--highlight-2 {
  background-color: var(--color-article-highlight-2-background);
  color: var(--color-text--inverted);

  a {
    color: inherit;
  }

  --headings__color: #FFFFFF;
}

.article--tint {
  background-color: var(--color-article-tint-background);
}

.article--dark {
  background: var(--color-article-dark-background);
  color: var(--color-article-dark-text);
}
