// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/LOGO
// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

// company brand
.logo {
  pointer-events: auto;
  margin: 1rem 1rem 1rem 0;

  img {
    margin-left: 0;
    width: 350px;
  }
}
