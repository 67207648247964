// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/FAQ
// --------------------------------------------------

// VARIABLES
html {
  --faq-border: var(--base-border);
}

// USED FROM _variables.scss

// --------------------------------------------------

// [1] call every headline element (h1-h6)
// [2] headline for FE-Module
.faq-list {
  @include navigation();
  
  > ul {
    margin-top: 2rem;
  }
  
  li {
    margin-bottom: var(--base-spacing-unit--sm);
  }

  li a {
    display: inline-block;
  }
  
  > { 
    #{headings()} { // [1]
      margin-bottom: 0;
      
      &:after {
        border-top: var(--faq-border);
        content: "";
        display: block; 
      }
    }
  }
  
  article > {
    #{headings()} { // [1]
      margin-bottom: 0;
    
      &:after {
        border-top: var(--faq-border);
        content: "";
        display: block; 
      }
    }
  }
}

.faq-page {
  > { 
    #{headings()} { // [1]
      margin-bottom: 0;
      
      &:after {
        border-top: var(--faq-border);
        content: "";
        display: block; 
      }
    }
  }
  
  article > {
    #{headings()} { // [1]
      margin-bottom: 0;
    
      &:after {
        border-top: var(--faq-border);
        content: "";
        display: block; 
      }
    }
  }
  
  .info {
    font-size: 0.875rem;    
    margin-top: var(--base-spacing-unit);
    opacity: 0.5;
  }
  
  section {
    padding: var(--base-spacing-unit) 0;
    border-bottom: var(--faq-border);
    
    &.last {
      margin-bottom: var(--base-spacing-unit);
    }
  }
}

// .faq-reader
//
.faq-reader {
  .info {
    font-size: 0.875rem;
   
    margin-top: var(--base-spacing-unit);    
    opacity: 0.5;
  } 
}
