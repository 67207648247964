// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// TRUMPS/DOCUMENTATION
// --------------------------------------------------

// VARIABLES

// --------------------------------------------------

// sass-lint:disable-all

// make grid visible
.show-grid {
	background-color: var(--color-gray);
	padding-top: var(--base-spacing-unit);
	margin-bottom: var(--base-spacing-unit);
	position: relative;

	&:before {
		font-size: var(--base-font-size--xs);
		padding: 0 var(--base-spacing-unit--xs);
		border-radius: 0 var(--base-border-radius) var(--base-border-radius) 0;
		background-color: var(--base-overlay-background);
		color: var(--color-text--inverted);
		position: absolute;
		top: 0;
		content: "screen-xs";
	}

	@include media-query(screen-sm) {
		&:before {
			content: "screen-sm";
		}
	}

	@include media-query(screen-md) {
		&:before {
			content: "screen-md";
		}
	}

	@include media-query(screen-lg) {
		&:before {
			content: "screen-lg";
		}
	}

	@include media-query(screen-xl) {
		&:before {
			content: "screen-xl";
		}
	}

	[class*="col-"] > * {
		background-color: var(--color-gray-light);
		padding: var(--base-spacing-unit--xs);
		border-radius: var(--base-border-radius);
	}
}

// color palettes
.color-palette {
	border-radius: var(--base-border-radius);
	padding: var(--base-spacing-unit--sm);
	margin-bottom: var(--base-spacing-unit);
	display: flex;
	flex: 1 0 auto;

	&--bordered {
		border: 1px solid var(--color-gray-light);
	}
}

.bg-highlight,
.bg-brand-primary { background-color: var(--color-band-highlight-background); }
.bg-brand-secondary { background-color: var(--color-brand-secondary); }
.bg-dark { background: var(--color-band-dark-background); }
.bg-tint { background-color: var(--color-band-tint-background); }
.bg-overlay {background: var(--base-overlay-background); }
.bg-gray { background-color: var(--color-gray); }
.bg-gray-dark { background-color: var(--color-gray-dark); }

// show/hide feedback button demo/dokumentation
.hide--Dokumentation {
	.link--dokumentation {
		display: none;
	}
}

.hide--Demo {
	.link--demo {
		display: none;
	}
}
