// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/NOTES
// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

.footer__note {
  font-family: var(--base-font-family-2);
  order: 2;

  @include media-query(screen-md) {
    order: inherit;
  }
}

.footer__contact {
  @include navigation;

  margin-bottom: var(--base-spacing-unit);

  @include media-query(screen-md) {
    margin-bottom: 0;
  }

  ul {
    display: inline-flex;
    text-align: center;
  }

  li {
    margin: 0 0.75rem;
  }
}
