// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/FAQ
// --------------------------------------------------

@import 'assets/nutshell/scss/components/faq';

// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE
.faq-list {
  h2 {
    @extend %_h3;
  }

  li {
    @extend %icon-arrow-right;

    a {
      text-decoration: none;
    }
  }
}

.faq-page {
  h2 {
    @extend %_h3;
  }
}
