// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

@import 'assets/nutshell/scss/components/navs';

// --------------------------------------------------

// CSS-VARIABLES
:root {
  --color-navigation-main:            var(--color-brand-primary);
  --color-navigation-main--active:    var(--color-text);
  --nav--main__item-padding:          0 0.66666rem 0;
  --nav--main__item-padding--sm:      0.25rem;
  --nav--main-font:                   var(--base-font-family-2);
  --nav--main-font-size:              1rem;
  --nav--main-font-weight:            700;

  --submenu-background:               #FFFFFF;

  --nav-mobile-color-background:      #FFFFFF;
  --nav-mobile-color-text:            var(--color-text);

  --menu-icon-width:                  1.25rem;
  --menu-icon-stroke:                 2px;

  --nav--sub__item-padding:           var(--base-spacing-unit--sm);
}

// --------------------------------------------------

.nav--right {
  justify-self: end;
}

// Main Navigation

.nav--main {
  line-height: 1em;
  font-size: var(--nav--main-font-size);
  font-family: var(--nav--main-font);
  color: var(--color-navigation-main);
  text-transform: uppercase;
  display: flex;
  flex: 1 1 auto;

  @include media-query(screen-lg) {
    --nav--main-font-size: 1.25rem;
  }

  li {
    @include media-query(screen-menu) {
      display: flex;
      align-items: center;
    }
  }

  // sass-lint:disable-block force-element-nesting
  a,
  a.trail,
  strong.active,
  strong.trail {
    padding: var(--nav--main__item-padding);
    letter-spacing: -0.025em;
    text-decoration: none;
    font-weight: var(--nav--main-font-weight);
    color: var(--color-navigation-main--active);

    @include media-query(screen-menu) {
      padding: var(--nav--main__item-padding);
      margin-bottom: 1rem;

    }

    @include media-query(screen-lg) {
      margin:  var(--nav--main__item-padding);
    }
  }

  a {
    color: inherit;

    &:hover {
      color: var(--color-navigation-main--active);
    }
  }

  .level_1 {

    > li {
      > a,
      > strong {
        white-space: nowrap;
      }

      > strong {
        position: relative;
        z-index: 1002;
      }
    }
  }

  li.submenu {
    // overflow: hidden; removed for submenu center styling
    position: relative;

    > a,
    > strong {
      position: relative;
      z-index: 1002;
    }

    @include media-query(screen-menu) {
      &.submenu--active,
      &.trail {
        .level_2 {
          &::before {
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
  }

  .level_2 {
    z-index: 1001;
    position: relative;
    font-size: var(--base-font-size);
    font-family: var(--base-font-family-1);

    @include media-query(screen-menu) {
      align-self: flex-end;
      top: 100%;
      flex-direction: column;
      min-width: 210px;
      max-height: 0;
      opacity: 0;
      position: absolute;
      left: calc(50% - 0.6666rem);
      transform: translate(-50%);
      margin: 0 0.6666rem 1rem;
      box-shadow: var(--base-box-shadow);
      transition: opacity 0.2s 0.3s, max-height 0s 0.6s, visibility 0s 0.6s;

      &::before {
        transition: opacity 0.2s 0.3s;
        top: -16px;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: var(--color-brand-primary);
        border-width: 8px;
        margin-left: -8px;
        opacity: 0;
      }
    }

    > li {
      padding: 0;

      @include media-query(screen-menu) {
        background: var(--submenu-background);
      }
    }

    @include media-query(screen-menu) {
      > li.first {
        border-top: 4px solid var(--color-brand-primary);
      }

      a {
        color: var(--color-brand-secondary);

        &:hover {
          color: var(--color-brand-primary);
        }
      }
    }

    a,
    strong.active,
    strong.trail {
      @include media-query(screen-menu) {
        width: 100%;
        white-space: nowrap;
        padding: 0.666666rem 1rem;
        text-transform: none;
        margin: 0;
        border-bottom: 4px solid var(--color-brand-primary);
      }
    }

    strong.active {
      @include media-query(screen-menu) {
        color:var(--color-brand-primary);
        background: rgb(247, 241, 225);
      }
    }
  }

  // sass-lint:disable-block no-mergeable-selectors
  li.submenu {
    &.submenu--active {
      .level_2 {
        z-index: 1004;
        max-height: 9999px;
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
      }
    }
  }

  .level_3 {
    flex-direction: column;

    @include media-query(screen-menu) {
      margin-left: var(--base-spacing-unit--sm);
    }

    a,
    strong.active,
    strong.trail {
      text-transform: none;

      &::before {
        content: '»';
        margin-right: var(--base-spacing-unit--xs);
      }
    }
  }
}

// sass-lint:disable-block no-vendor-prefixes
.nav--mobile {
  @include media-query(screen-menu-max) {
    transition: transform 0.3s;
    z-index: 1004;
    visibility: visible;
    font-size: 1.25rem;
    position: fixed;
    background: var(--nav-mobile-color-background);
    left: 0;
    right: 0;
    top: var(--nav-mobile-padding-top, 0);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: touch;
    -ms-overflow-style: none;
    transform: translate(-100%, 0);
    pointer-events: auto;

    a,
    a.trail,
    strong.active {
      padding-left: 5%;
      padding-right: 5%;
    }

    .level_1 {
      flex: 1 1 auto;

      > li {

        &.first {
          border-top: 4px solid var(--color-brand-primary);
        }

        > a,
        > strong {
          border-bottom: 4px solid var(--color-brand-primary);
          color: var(--nav-mobile-color-text);
          text-overflow: ellipsis;
          //overflow: hidden;
          white-space: nowrap;
          display: block;
          padding: 1rem 1rem 1rem 1rem;

          &.submenu {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 1rem;
              margin-top: -0.5rem;

              display: inline-block;
              width: 1rem;
              height: 1rem;
              vertical-align: middle;
              mask-image: url('/files/inhalte/icons/arrow-right.svg');
              mask-position: center;
              mask-size: contain;
              mask-repeat: no-repeat;
              background-color: var(--color-brand-primary);
            }
          }
        }

        &.submenu--active {
          > a,
          > strong {
            &::after {
              transform: rotate(90deg);
            }
          }
        }

        > strong {
          color: var(--color-brand-primary);
        }
      }
    }

    li {
      transition: opacity 0.3s 0.3s;
      opacity: 0;
    }

    .level_2 {
      overflow: hidden;
      background: rgb(239, 239, 239);
      max-height: 0;
      transition: max-height 0.3s;

      li.last {
        border-bottom: 4px solid var(--color-brand-primary);
      }

      a,
      strong {
        padding: 1rem 1rem;
        color: var(--nav-mobile-color-text);
        text-transform: none;
        font-weight: 400;
        border-bottom: 2px solid var(--color-brand-primary);

        &.last {
          border-bottom: none;
        }
      }

      strong {
        font-weight: 700;
        color: var(--color-brand-primary);
      }
    }
  }
}

.nav--mobile--active {
  @include media-query(screen-menu-max) {

    transform: translate(0, 0);
    transition: transform 0.3s;

    li {
      opacity: 1;
    }
  }
}

// button to display mobile navigation
.nav-toggler {
  justify-self: end;
  align-self: center;
  z-index: 1002;
  pointer-events: auto;
  margin-left: 2rem;

  @include media-query(screen-menu) {
    display: none;
  }
}

.nav-toggler__button {
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  cursor: pointer;
  color: var(--color-brand-primary);
  padding: calc(var(--base-spacing-unit--xs)/2) var(--base-spacing-unit--xs);
  line-height: 1.5;


  .menu-icon {
    position: relative;
    width: var(--menu-icon-width);
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
  }

  .menu-icon__inner {
    width: 100%;
    background-color: currentcolor;
    position: absolute;
    left: 0;
    top: 40%;
    margin-top: calc(var(--menu-icon-stroke) / -2);
    height: var(--menu-icon-stroke);
    transition: transform 0.15s ease;

    &::after,
    &::before {
      background-color: currentcolor;
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: var(--menu-icon-stroke);
      transition: transform 0.15s ease, opacity 0.15s;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }

    .html--fixed & {
      transform: rotate(45deg);

      &::before {
        opacity: 0;
      }

      &::after {
        transform: rotate(-90deg);
        bottom: 0;
      }
    }
  }

  .menu-label {
    display: inline-block;
    margin-left: var(--base-spacing-unit--xs);
    font-size: var(--base-font-size--xl);
  }
}

.nav--sub {
  margin-bottom: var(--base-spacing-unit--lg);
  font-size: var(--base-font-size--xs);
  position: relative;
  z-index: 1000;

  &::after {
    content: '';
    border-bottom: var(--base-border);
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    bottom: 0;
  }

  ul {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    touch-action: auto;
    -ms-overflow-style: none;
  }

  li {
    flex: 1 0 auto;
    white-space: nowrap;
    position: relative;
    z-index: 1001;
    text-align: center;

    &.active,
    &.trail {
      border-bottom: var(--base-border-width) solid currentcolor;
    }

    &:hover {
      border-bottom: var(--base-border-width) solid var(--color-links-hover);
    }
  }

  a,
  strong.active,
  strong.trail {
    color: inherit;
    padding: var(--nav--sub__item-padding);
  }

  a:hover {
    color: var(--color-links-hover);
  }
}

.nav--meta {
  font-family: var(--nav--main-font);
  font-weight: var(--nav--main-font-weight);

  ul {
    @include media-query(screen-md) {
      display: flex;
    }
  }

  li {
    display: inline-block;

    &::after {
      content: '|';
    }

    &.last {
      &::after {
        content: '';
      }
    }
  }

  a,
  strong.active,
  strong.trail {
    display: inline-block;
    padding: 0 var(--base-spacing-unit--sm);
    text-decoration: none;
    color: var(--color-text--inverted);
    font-weight: normal;
  }
}
