/**
  * Erdmann & Freunde
  * LASR Contao Theme
  * erdmann-freunde.de/produkte/contao-themes/lasr/
  *
  */

// VARIABLES
@import 'variables';


// MIXINS
@import 'mixins/general';
@import 'mixins/responsive';


// GENERIC (load these directly from nutshell, as we don't need additional normalize, resets and box-sizing)
@import 'assets/nutshell/scss/generic/normalize';
@import 'assets/nutshell/scss/generic/contao-reset';
@import 'assets/nutshell/scss/generic/box-sizing';


// BASE
@import 'base/shared';
@import 'base/page';
@import 'base/fonts';
@import 'base/type';
@import 'base/grid';
@import 'base/layout';
@import 'base/icons';


// COMPONENTS
// .... lasr theme + nutshell loading
@import 'components/media';
@import 'components/links';
@import 'components/navs';
@import 'components/slider';
@import 'components/news';
@import 'components/events';
@import 'components/forms';
@import 'components/accordion';
@import 'components/downloads';
@import 'components/tables';
@import 'components/newsletter';
@import 'components/faq';
@import 'components/comments';
@import 'components/search';
// .... lasr theme only
@import 'components/logo';
@import 'components/hero';
@import 'components/contact';
@import 'components/facts';
@import 'components/jobs';
@import 'components/notes';
@import 'components/portfolio';

@import 'components/card';
@import 'components/text';


// TRUMPS
@import 'trumps/helper';
@import 'trumps/print';
@import 'trumps/documentation';
@import 'trumps/browser-support';
