// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// MIXINS/GENERAL
// --------------------------------------------------

@import 'assets/nutshell/scss/mixins/general';

// --------------------------------------------------

// VARIABLES

// --------------------------------------------------

@mixin padding-default {
  padding-left:  0;
  padding-right: 0;
}
