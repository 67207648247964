// *
// * Erdmann & Freunde
// * LASR Contao Theme
// * erdmann-freunde.de/produkte/contao-themes/lasr/
// *

//
// COMPONENTS/EVENTS
// --------------------------------------------------

@import 'assets/nutshell/scss/components/events';

// --------------------------------------------------

// CSS-VARIABLES

// --------------------------------------------------

.events-list {
  display: grid;
  grid-column-gap: var(--grid__gutter);

  @include media-query(screen-sm) {
    grid-template-columns: repeat(12, 1fr);
  }

  > {
    #{headings()} {
      @extend %_h5;

      padding-bottom: var(--base-spacing-unit--sm);

      @include media-query(screen-sm) {
        grid-column: 3 / 10 span;
      }

      @include media-query(screen-md) {
        grid-column: 3 / 10 span;
      }

      @include media-query(screen-lg) {
        grid-column: 3 / 8 span;
      }
    }
  }

  .header {
    margin-top: var(--grid__gutter);
    font-size: var(--base-font-size--xs);
    align-self: start;
    justify-self: start;

    @include media-query(screen-sm) {
      grid-column: 1 / 2 span;

      .day,
      .date {
        display: block;
        white-space: nowrap;

        @supports not (display: grid) {
          display: inline-block;
        }
      }
    }
  }

  .layout_list,
  .layout_teaser {
    padding-top: var(--grid__gutter);
    border-top: var(--base-border);
    padding-bottom: var(--grid__gutter);

    &:last-child {
      padding-bottom: var(--grid__gutter);
      border-bottom: var(--base-border);
    }

    @include media-query(screen-sm) {
      grid-column: 3 / 10 span;
    }

    @include media-query(screen-md) {
      grid-column: 3 / 10 span;
    }

    @include media-query(screen-lg) {
      grid-column: 3 / 8 span;
    }

    #{headings()} {
      margin-top: 0;

      a {
        color: inherit;
      }
    }

    .time {
      line-height: 1.25;
      margin-bottom: var(--base-spacing-unit--sm);
    }
  }

  .layout_upcoming {
    @include make-row;

    grid-column: 1 / -1;
    border-bottom: var(--base-border);
    padding-bottom: var(--base-spacing-unit);

    &:first-child {
      border-top: var(--base-border);
    }

    time {
      @include make-col(3);

      @include media-query(screen-sm) {
        @include make-col(2);
      }
    }

    a {
      @include make-col(9);

      @include media-query(screen-sm) {
        @include make-col(10);
      }

      align-self: center;
    }
  }

  .ce_text {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .more {
    a {
      --paragraph__link-decoration: none;
    }
  }

  .empty {
    margin-top: var(--base-spacing-unit--sm);
    margin-bottom: var(--base-spacing-unit--sm);
  }
}

.events-reader {
  .info {
    font-weight: 700;
    margin-bottom: var(--base-spacing-unit--sm);
  }

  .ce_text {
    &:first-of-type {
      font-size: var(--base-font-size--lg);
      line-height: 1.6;
    }
  }
}

.events-menu {

  > {
    #{headings()} {
      @extend %_h5;

      border-bottom: var(--base-border);
      padding-bottom: var(--base-spacing-unit--sm);
    }
  }

  a,
  .active {
    text-decoration: none;
    color: inherit;
    font-family: var(--headings__font-family);
  }

  .level_2 {
    a,
    span.active {
      vertical-align: middle;
      text-decoration: none;

      &::before {
        content: '»';
        margin-right: calc(var(--base-spacing-unit--xs) / 2);
      }
    }
  }

  .year {
    > a,
    > .active {
      font-weight: var(--headings__font-weight);
    }
  }
}

.events-calendar {
  font-size: var(--base-font-size--xs);

  @include media-query(screen-sm-max) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: auto;
    -ms-overflow-style: none;
  }

  > {
    #{headings()} {
      @extend %_h5;

      padding-bottom: var(--base-spacing-unit--sm);
      margin-bottom: 0;
    }
  }

  th,
  td {
    padding: calc(var(--base-spacing-unit--xs) / 2);
    text-align: center;
  }

  .days {
    width: percentage(1/7);
  }
}
